import AOS from 'aos';
import 'objectFitPolyfill';
import DiadaoCookies from './cookies/cookies';

jQuery(document).ready(() => {
    window.$ = jQuery;

    DiadaoCookies.init();
    // cookies.changeAcceptAllBtnAction();
    setTimeout(function(){
        // le LOADER
        $('body').addClass('loaded');

        // pour trigger le out laisser impérativement le mirror:true
        AOS.init({
            // once: false,
            offset: 0,
            mirror: true,
        })
    }, 500);

    /**
     * SLIDER DES SUITES
     */

    // Applique le object-fit-cover via le polyfill sur le slider chambre pour IE11
    // var $owl = $('.mkdf-pl-gallery-full-height');
    var $owlImg = $('.mkdf-pl-gallery-full-height img');
    $owlImg.attr('data-object-fit', 'cover');
    objectFitPolyfill($owlImg);


    $('.text-image-slick').each(function () {
        // utilisation du each/$(this) pour contourner le problème si plusieurs slick sur la même page
        $(this).slick({
            speed: 1000,
            arrows: true,
            autoplay: true,
            prevArrow: '<span class="slick-prev">' +
            '<i class="icon-diadao-arrow-left">' +
            '</span>',
            nextArrow: '<span class="slick-next">' +
            '<i class="icon-diadao-arrow-right"></i>' +
            '</span>',
            appendArrows: $(this).parents('.text-image__images').find('.controls'),
        });
    });

    var $headerSlideshow = $('.header-slideshow-slick');
    function headerSlideshowSlick($slideshow){
        $slideshow.slick({
            speed: 1000,
            arrows: true,
            autoplay: true,
            prevArrow: '<span class="slick-prev">' +
            '<i class="icon-diadao-arrow-left">' +
            '</span>',
            nextArrow: '<span class="slick-next">' +
            '<i class="icon-diadao-arrow-right"></i>' +
            '</span>',
            appendArrows: ".header-slideshow .controls",
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        speed: 500
                    }

                }
            ]
        });
    }
    headerSlideshowSlick($headerSlideshow);


    /*
    Gestion de la vidéo du header
    */
    var $videoHeader = $('.header-slideshow__video video');
    var $videoPlay = $('.header-slideshow .videoReplay .play');
    var $videoStop = $('.header-slideshow .videoReplay .stop');
    var $iconVideo = $('.header-slideshow .videoReplay .iconVideo');
    var $videoReplay = $('.header-slideshow').find('.videoReplay');
    var $iconPlay = $('.header-slideshow .videoReplay').find('.icon-diadao-play');
    var $iconStop = $('.header-slideshow .videoReplay').find('.icon-diadao-close');

    // Gestion de l'évènement de la vidéo - Play
    document.addEventListener('aos:in:triggerVideo', ( detail ) => {
        if ($videoHeader.length) {
            $videoHeader[0].play();
        }
    });

    // Gestion de l'évènement de la vidéo - Pause
    document.addEventListener('aos:out:triggerVideo', ( detail ) => {
        if ($videoHeader.length) {
            $videoHeader[0].pause();
        }
    });

    // Quand la vidéo démarre
    function onVideoStart(video) {
        console.log('début vidéo');

        if (!$headerSlideshow.hasClass('hidden')) {
            $videoReplay.addClass('visible');
            $headerSlideshow.slick('slickPause');
            $videoStop.addClass('visible');
            $iconVideo.addClass('icon-diadao-close');
        }
    }

    // Quand la vidéo se termine
    function onVideoEnd(video) {
        console.log('fin vidéo');

        $headerSlideshow.slick('slickPlay');
        hideVideo(video);
    }

    function showVideo($video) {
        $videoHeader.closest('.header-slideshow__video').removeClass('hidden');
        $iconVideo.toggleClass('icon-diadao-play');
        $iconVideo.addClass('icon-diadao-close');
        $videoPlay.toggleClass('visible');
        $videoStop.addClass('visible');
        $videoHeader[0].play();
        $headerSlideshow.slick('slickPause');
    }

    function hideVideo($video) {
        $headerSlideshow.addClass('hidden');
        $iconVideo.toggleClass('icon-diadao-close');
        $iconVideo.addClass('icon-diadao-play');
        $videoStop.toggleClass('visible');
        $videoPlay.addClass('visible');
        $videoHeader.closest('.header-slideshow__video').addClass('hidden');
        $videoHeader[0].pause();
        $headerSlideshow.slick('slickPlay');
        // $videoHeader.addClass('stopped');
    }

    $videoReplay.on('click', '.play, .icon-diadao-play', function() {
        showVideo();
    });

    $videoReplay.on('click', '.stop, .icon-diadao-close', function() {
        hideVideo();
    });

    if($videoHeader.length === 1) {

        $videoHeader.on('ended', function (e) {
            onVideoEnd(e.currentTarget);
        });

        $videoHeader.on('play', function (e) {
            console.log('test video');

            onVideoStart(e.currentTarget);
        });
    }


    /*
    Helper Main pour les tableaux sur mobile
    */
    function applyStyles(){
        $('.table_wrapper').append('<div class="picto"></div>');
        $('.table_wrapper').one('click scroll', function(e){
            $(this).addClass('useraction');
        });
    }
    applyStyles();


    /*
    Quand le carousel est initialisé on récupère l'ID du Post et la requète Ajax nous permet ensuite de récupérer le prix de chaque chambre
    CF : functions.php get_room_price()
    */
    var owl = $('.mkdf-owl-slider');
    if(owl) {
        var roomSlider = $('#roomSlider');
        roomSlider.find('.owl-item article').each(function() {
            var postId = $(this).attr('class').match('[0-9]+');
            var that = $(this);
            that.find('.mkdf-pli-text').append('<div class="roomPrice">'+ '<img class="spinner" src="/wp-content/themes/quart-child/assets/img/spinner.gif"></div>');
            jQuery.post(
                ajaxurl,
                {
                    'action': 'get_room_price',
                    'id': postId
                },
                function(response){
                    that.find('.roomPrice').html(response);
                }
            );
        });
    }



    // Contrer le # du menu burger qui fait remonter la page à l'ouverture du menu horizontal
    var burgerMenu = $(".mkdf-vertical-area-opener");
    burgerMenu.on("click tap", function(e) {
        e.preventDefault();
        e.stopPropagation(); // test
    })

    $(document).click(function() {
        $('.mkdf-vertical-menu-area').removeClass('active');
        $('.mkdf-vertical-area-bottom-logo').removeClass('active');
    });


    // placer le selecteur de langue dans le menu mobile
    var langSelector = $('.langSelector');
    var $mobileNav = $('.mkdf-mobile-header-inner').find('.mkdf-mobile-nav .mkdf-grid');
    langSelector.clone().appendTo($mobileNav);


    // Ajout du calendrier à la place du big Calendar en Mobile
    if ($(window).width() < 768) {
        $('.mkdf-vertical-align-containers .mkdf-vertical-align-containers').append('<a class="book-link-menu" href="https://widgets.secure-hotel-booking.com/Au-Clos-du-Lac/JB6B/22311/fr/" target="_blank">réserver</a>');
    }


    // fait remonter le bouton réserver sur tablette quand le menu disparait
    var searchClass = 'mobile-header-appear';
    var searchAnimate = 'mkdf-animate-mobile-header';

    var observer = new MutationObserver(function( mutations ) {
        mutations.forEach( function( mutation ) {
            var $target = $(mutation.target);
            var $mobileHeaderInner = $target.find('.mkdf-mobile-header-inner');
            var currentClass = mutation.target.className;
            var $bigCalendar = $('.bigcalendar-container');
            var $backbutton = $('.button__back');


            if( mutation.attributeName === 'class' ) {
                if ( ( $target.hasClass(searchAnimate) && !$target.hasClass(searchClass) ) ) {
                    $bigCalendar.addClass('go-up');
                    $backbutton.addClass('go-up');

                } else {
                    $bigCalendar.removeClass('go-up');
                    $backbutton.removeClass('go-up');
                }
            }
        });
    });

    var $el = $('.mkdf-mobile-header');
    observer.observe( $el[0], {
    attributes: true
    });

    // Scroll 100vh down
    $('.anchor-btn').on("click", function(event) {

        if ($(window).width() < 768) {
            $("html, body").animate({
                scrollTop: ($(window).height() - 60)  //Get the document height - navigation bar
            }, "slow"); //Animates the scroll
        }
        else {
            $("html, body").animate({
                scrollTop: $(window).height()  //Get the document height
            }, 800); //Animates the scroll
        }
    });
    // OREJIME //
})

